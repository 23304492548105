import dayjs from "dayjs";
import numeral from "numeral";

export default {
    data: () => ({
        debounce_timeout: null
    }),
    computed: {
        checkENVDev() {
            return process.env.VUE_APP_ENV === "development" || process.env.VUE_APP_ENV === "local";
        }
    },
    methods: {
        evalutateProperty: property => eval(`this.${property}`),
        evalutatePropertyWithCondition: property => eval(`${property}`),
        parseAmount(amount) {
            if (!amount) return;
            amount = amount.replace("£", "");
            if (/[a-z]/.test(amount)) return amount;
            return `£${numeral(amount).format("0,0[.]00")}`;
        },
        parseDate(dateParam) {
            if (!dateParam) return;
            let date = new Date(dateParam);
            if (date === "Invalid Date") {
                date = new Date(dayjs(dateParam, "DD/MM/YYYY").format("YYYY-MM-DD"));
            }
            return date;
        },
        parseEntityDate(dateParam) {
            if (!dateParam) return;
            let date = new Date(dayjs(dateParam, "DD/MM/YYYY").format("YYYY-MM-DD"));
            if (date == "Invalid Date") {
                date = new Date(dateParam);
            }
            return date;
        },
        formatDate(dateParam) {
            if (!dateParam) return;
            let date = dayjs(dateParam).format("YYYY-MM-DD");
            return date;
        },
        formatReadDate(dateParam) {
            if (!dateParam) return;
            let date = dayjs(dateParam).format("DD/MM/YYYY");
            return date;
        },
        formatReadDateTime(dateParam) {
            if (!dateParam) return;
            let date = dayjs(dateParam).format("ddd, MMM D, YYYY h:mm A");
            return date;
        },
        formatCurrency(amount) {
            if (!amount) return;
            let a = amount / 100;
            return parseFloat(a)
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
        },
        formatPennies(amount) {
            if (!amount) return;
            if (typeof amount == "number") return;
            return Number(amount.replace(/[^0-9.-]+/g, "")) * 100;
        },
        scrollToElement(ele) {
            var elmnt = document.querySelector(ele);
            if (!elmnt) {
                return;
            }
            elmnt.scrollIntoView({
                behavior: "smooth",
                block: "center"
            });
        },
        scrollToError() {
            setTimeout(() => this.scrollToElement(".has-error"), 500);
        },
        scrollToTop() {
            setTimeout(() => this.scrollToElement(".scroll-top"), 250);
        },
        getGreenToRed(percent) {
            if (!percent) return "rgb(255,0,0,0)";
            const r = parseFloat(255 - (255 * percent) / 100).toFixed(1);
            const g = parseFloat((255 * percent) / 100).toFixed(1);
            return "rgb(" + r + "," + g + ",0)";
        },
        formatInd(val) {
            return val
                .split("_")
                .map(v => v.toUpperCase())
                .join(" ");
        },
        formatAddress(address) {
            if (!address) return "";
            return `
        ${address.address_1 ? address.address_1 + "," : ""}
        ${address.address_2 ? address.address_2 + "," : ""}
        ${address.address_3 ? address.address_3 + "," : ""}
        ${address.town ? address.town + "," : ""}
        ${address.county ? address.county + "," : ""}
        ${address.country ? address.country + "," : ""}
        ${address.postcode ? address.postcode : ""}
      `;
        },
        formatAddressHTML(address, withDate = false) {
            if (!address) return "";
            let addressTmp = `
        ${
                address.address_1
                    ? "<b>Address Line 1:</b> " + address.address_1 + ", <br>"
                    : ""
            }
        ${
                address.address_2
                    ? "<b>Address Line 2:</b> " + address.address_2 + ", <br>"
                    : ""
            }
        ${
                address.address_3
                    ? "<b>Address Line 3:</b> " + address.address_3 + ", <br>"
                    : ""
            }
        ${address.town ? "<b>Town:</b> " + address.town + ", <br>" : ""}
        ${address.county ? "<b>County:</b> " + address.county + ", <br>" : ""}
        ${
                address.country ? "<b>Country:</b> " + address.country + ", <br>" : ""
            }
        ${address.postcode ? "<b>Postcode:</b> " + address.postcode : ""}
      `;

            if (withDate) {
                addressTmp += `
          ${
                    address.from_date
                        ? "<br><strong>From:&nbsp;</strong>" +
                        address.from_date +
                        ", <br>"
                        : ""
                }
          ${
                    address.to_date
                        ? "<strong>To:&nbsp;</strong>" + address.to_date
                        : ""
                }
        `;
            }

            return addressTmp;
        },
        formatPensionReportRequest(tempParams) {
            if (!tempParams) return;

            // amounts
            if (tempParams.advisor_fees) {
                tempParams.advisor_fees = this.formatPennies(tempParams.advisor_fees);
            }
            if (tempParams.fund_management_fee) {
                tempParams.fund_management_fee = this.formatPennies(
                    tempParams.fund_management_fee
                );
            }
            if (tempParams.fund_value) {
                tempParams.fund_value = this.formatPennies(tempParams.fund_value);
            }
            if (tempParams.transfer_value) {
                tempParams.transfer_value = this.formatPennies(
                    tempParams.transfer_value
                );
            }

            return tempParams;
        },
        formatLearningdata(tpuuid, tempParams, entities, informationSource) {
            if (!tempParams) return;
            for (let i in entities) {
                for (let j in tempParams) {
                    if (j == entities[i].FieldName) {
                        if (tempParams[j]) {
                            entities[i].ParsedValue = tempParams[j];
                        }
                        if (informationSource[j]) {
                            entities[i].InformationSource =
                                informationSource[j].information_source;
                            entities[i].Notes = informationSource[j].notes;
                        }
                    }
                }
            }

            return {
                tpuuid,
                entities
            };
        },
        handleSelectMixin({e, name, mlFieldType, setAggregationReportValue}) {
            let fieldType = mlFieldType;
            let value = e.target.value;

            switch (fieldType) {
                case "Number":
                    value = this.formatPennies(value);
                    break;
            }
            setAggregationReportValue({
                key: name,
                value: value
            });
        },
        formulateErrorString(err) {
            if (err.response.data.error) return err.response.data.error;
            let string = null;
            let errors = err.response.data.errors || [];
            string = `${err.response.data.message}<br/>`;
            for (let i in errors) {
                string += `${errors[i]}<br/>`;
            }
            return string;
        },
        affirm(bool) {
            if (bool == null) return "No";
            return bool ? "Yes" : "No";
        },
        setDataObject({params, response}) {
            if (!response) return;
            if (response?.data.length === 0) return;
            return {
                associationProviderId: params.id,
                name: response.data.name,
                providerAddress:
                    response.data.address.length > 0
                        ? this.formatAddress(response.data.address[0])
                        : this.missingText,
                id: response.data.id,
                pensionProviderEmail: response.data.email,
                pensionProviderContact: response.data.current_phone_number_formatted,
                communicationByPost: response.data.requires_communication_by_post,
                requiresWetSignature: response.data.requires_wet_signature,
                requiresEncryption: response.data.requires_encryption,
                responseGoesToCustomer: response.data.response_goes_to_customer
            };
        },
        debounce(fn) {
            clearTimeout(this.debounce_timeout);
            this.debounce_timeout = setTimeout(fn, 500);
        },
        deCamel(text) {
            if (!text) return;
            let regex = new RegExp(/[-|_]/g);
            text = text.replace(regex, " ");
            let textArr = text.split("");
            textArr[0] = textArr[0].toUpperCase();
            text = textArr.join("");
            return text;
        },
        formatNote(noteArray) {
            if (typeof noteArray === "undefined" || noteArray?.length === 0) return null;
            const rule = /^\s*\n/gm;
            return noteArray[noteArray?.length - 1]?.content?.replace(rule, '');
        },
        defineName(name) {
            if (this.ind) return name + "_" + this.ind;
            return name;
        },
        operatorsArray() {
            return [
                {
                    id: '>',
                    text: 'more than',
                }, {
                    id: '<',
                    text: 'less then',
                }, {
                    id: '===',
                    text: 'equal to',
                }, {
                    id: '!==',
                    text: 'not equal to',
                },
            ];
        },
        async loadUnnassignedEmails() {
            this.emailResponse = await this.fetchUnnassignedEmails().catch(err =>
                console.log(err)
            );
        },
        async loadAccountEmails() {
            this.accountEmail = await this.fetchAccountInbox().catch(err =>
                console.log(err)
            );
        },
        getLast(arr) {
            if (!arr || arr.length === 0) return [];
            return arr[arr.length - 1];
        },
        async loadStatusLists() {
            let response = await this.fetchStatusList().catch((err) => console.log(err));
            if (response?.status === 200) {
                this.setApplicationStatus(this.buildStatusList(response?.data.data['application']));
                this.setTraceStatus(this.buildStatusList(response?.data.data['pension-trace']));
                this.setClosedConditions(this.buildStatusList(response?.data.data['closed-conditions']));
            }
        },
        buildStatusList(data) {
            let tempArr = [];
            for (let i in data) {
                tempArr.push({
                    id: data[i],
                    text: this.deCamel(data[i])
                });
            }
            return tempArr;
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        sanitizeHTML(content) {
            if (!content) return '';

            // Check if content contains potentially malicious elements
            const hasMaliciousContent = /javascript:|on\w+=|data:|vbscript:|expression\(|<script|<style/i.test(content);
            
            // If no malicious content detected, return original content
            if (!hasMaliciousContent) {
                return content;
            }
            
            // Create a temporary div element
            const tempDiv = document.createElement('div');
            
            // Set the content as text to strip any HTML
            tempDiv.textContent = content;
            
            // Get the sanitized content
            let sanitized = tempDiv.innerHTML;
            
            // Remove only malicious elements
            sanitized = sanitized
                .replace(/javascript:/gi, '') // Remove javascript: protocol
                .replace(/on\w+=/gi, '') // Remove event handlers
                .replace(/data:/gi, '') // Remove data: protocol
                .replace(/vbscript:/gi, '') // Remove vbscript: protocol
                .replace(/expression\(/gi, '') // Remove CSS expressions
                .replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '') // Remove script tags
                .replace(/<style\b[^<]*(?:(?!<\/style>)<[^<]*)*<\/style>/gi, ''); // Remove style tags
            
            return sanitized;
        },
    }
};
