<template>
  <div class="breadcrumb" v-if="links">
    <div class="breadcrumb-inner" v-for="(link, ind) in links" :key="ind">
      <span
        :class="breadcrumbStyle(ind)"
        @click="handleNav(link)"
        v-html="sanitizeHTML(link.name)"
      ></span>
      <span class="no-underline" v-if="ind + 1 !== links.length"
        >&nbsp;/&nbsp;</span
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "BreadCrumb",
  props: {
    links: {
      type: Array,
      default: null
    }
  },
  methods: {
    handleNav(link) {
      this.$router.push({
        ...link
      });
    },
    breadcrumbStyle(ind) {
      return {
        "link-text -disabled": ind + 1 === this.links.length,
        "link-text": ind + 1 < this.links.length
      };
    },
  },
  mounted() {}
};
</script>
<style lang="scss" scoped>
.breadcrumb {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: calc($spacingBase / 2) $spacingBase;
}
</style>
